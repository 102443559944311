<template>
  <v-row style="margin: 5px;background: #CCCCCC80">
    <v-col
      cols="12"
      sm="12"
    >
      <v-text-field
        v-model="key"
        :label="$t('dtouch.formCio.key', locale)"
        outlined
        dense
        hide-details
        @input="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
    >
      <translate
        v-model="name"
        :languages="languages"
        :label="$t('dtouch.formCio.name', locale)"
        @input="handleChange"
        type="text"
        style="margin: 0;"
      />
    </v-col>
  </v-row>
</template>
<script>
import Translate from '@/components/Translate'
export default {
  components: {
    Translate,
  },
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    id: '',
    key: '',
    name: {es: ''},
  }),
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.id = this.editedItem.id
      this.key = this.editedItem.key
      this.name = JSON.parse(JSON.stringify(this.editedItem.name))
    },
    handleChange () {
      this.onChange({
        id: this.id,
        key: this.key,
        name: this.name
      })
    },
  },
}
</script>

