<template>
  <v-row style="margin: 5px;background: #CCCCCC80">
    <v-col
      cols="12"
      sm="12"
    >
      <v-text-field
        v-model="key"
        :label="$t('dtouch.formCio.key', locale)"
        outlined
        dense
        hide-details
        @input="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
    >
      <translate
        v-model="label"
        :languages="languages"
        :label="$t('dtouch.formCio.label', locale)"
        @input="handleChange"
        type="text"
        style="margin: 0;"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
    >
      <v-select 
        v-model="type"
        :items="['info', 'string', 'date', 'select', 'selectMultiple', 'checkbox']"
        :label="$t('dtouch.formCio.type', locale)"
        outlined
        dense
        hide-details
        @change="handleChange"
      />
      <!--v-text-field
        v-model="type"
        :label="$t('dtouch.formCio.type', locale)"
        outlined
        dense
        hide-details
        @input="handleChange"
      /-->
    </v-col>
    <v-col
      v-if="type !== 'info'"
      cols="12"
      sm="12"
    >
      <v-checkbox
        v-model="required"
        :label="$t('dtouch.formCio.required', locale)"
        @change="handleChange"
        hide-details
      />
    </v-col>
    <v-col
      v-if="type !== 'info'"
      cols="12"
      sm="12"
    >
      <v-text-field
        v-model="value"
        :label="$t('dtouch.formCio.value', locale)"
        outlined
        dense
        hide-details
        @input="handleChange"
      />
    </v-col>
    <v-template v-if="['select', 'selectMultiple'].indexOf(type) >= 0">
    <v-col
      cols="12"
      sm="12"
      v-for="(item, index) of items"
      :key="item.id"
      style="border: 1px solid green;"
    >
      <small style="color: green;">Valor {{ index+1}}:</small>
      <small style="color: red;" @click="handleDeleteItem(item.id)"> [delete]</small>
      <form-value
        :editedItem="item"
        :locale="locale"
        :languages="languages"
        :onChange="handleChangeValue"
      />
    </v-col>

    <v-col
      cols="12"
      sm="12"
      style="text-align: center;"
    >
      <v-btn @click="handleAddValue" block color="green"> add value</v-btn>
    </v-col>
</v-template>
  </v-row>
</template>
<script>
const defaultField = {
  key: '',
  name: { es: ''},
}
import utils from '@/services/utils'
import Translate from '@/components/Translate'
import FormValue from './FormValue'
export default {
  components: {
    Translate,
    FormValue,
  },
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    id: null,
    key: null,
    label: { es: ''},
    type: null,
    fields: [],
    required: false,
    value: null,
    items: [],
  }),
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.id = this.editedItem.id
      this.key = this.editedItem.key
      this.label = JSON.parse(JSON.stringify(this.editedItem.label))
      this.type = this.editedItem.type
      this.required = this.editedItem.required
      this.value = this.editedItem.value
      this.items = JSON.parse(JSON.stringify(this.editedItem.items))
    },
    handleChangeValue (v) {
      for (let i = 0; i < this.items.length ; i++) {
        if (this.items[i].id === v.id) this.items[i] = v
      }
      this.handleChange()
    },
    handleChange () {
      this.onChange({
        id: this.id,
        key: this.key,
        label: this.label,
        type: this.type,
        required: this.required,
        value: this.value,
        items: this.items
      })
    },
    handleAddValue() {
      const aux = JSON.parse(JSON.stringify(defaultField))
      aux.id = JSON.parse(JSON.stringify(utils.getNewID()))
      this.items.push(aux)
      this.handleChange()
    },
    handleDeleteItem (v) {
      this.items = this.items.filter(x => x.id !== v)
      this.handleChange()
    },
  },
}
</script>

