<template>
  <v-row style="background: #CCCCCC80;padding: 5px;">
    <v-col
      cols="12"
      sm="12"
    >
      <v-text-field
        v-model="name"
        :label="$t('dtouch.formCio.name', locale)"
        outlined
        dense
        hide-details
        @input="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
    >
      <translate
        v-model="messageConfirm"
        :languages="languages"
        :label="$t('dtouch.formCio.messageConfirm', locale)"
        @input="handleChange"
        type="text"
        style="margin: 0;"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
    >
      <v-text-field
        v-model="notificationMails"
        :label="$t('dtouch.formCio.notificationMails', locale)"
        outlined
        dense
        hide-details
        @input="handleChange"
      />
      <small>concatenar con ';'</small>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      v-for="(item, index) of config"
      :key="item.ID"
      style="margin: 5px 0;border: 1px solid blue;"
    >
      <small style="color: blue;">Campo {{ index+1}}:</small>
      <small style="color: red;" @click="handleDeleteItem(item.id)"> [delete]</small>
      <form-field
        :editedItem="item"
        :locale="locale"
        :languages="languages"
        :onChange="handleChangeFields"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      style="text-align: center;"
    >
      <v-btn @click="handleAddField" color="blue"> add field</v-btn>
    </v-col>
  </v-row>
</template>
<script>
const defaultField = {
  key: '',
  label: { es: ''},
  type: 'string',
  required: false,
  items: [],
  value: null,
}
import Translate from '@/components/Translate'
import utils from '@/services/utils'
import FormField from './FormField'
export default {
  components: {
    Translate,
    FormField,
  },
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    id: null,
    name: {es: ''},
    notificationMails: null,
    messageConfirm: {es: 'Registro creado correctamente'},
    config: [],
  }),
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      console.log(this.editedItem)
      this.id = this.editedItem.ID
      this.name = JSON.parse(JSON.stringify(this.editedItem.Name))
      this.notificationMails = this.editedItem.NotificationMails
      this.messageConfirm = JSON.parse(JSON.stringify(this.editedItem.MessageConfirm))
      this.config = JSON.parse(JSON.stringify(this.editedItem.Config))
    },
    handleChangeFields (v) {
      for (let i = 0; i < this.config.length ; i++) {
        if (this.config[i].id === v.id) this.config[i] = v
      }
      this.handleChange()
    },
    handleChange () {
      this.onChange({
        ID: this.id,
        Name: this.name,
        MessageConfirm: this.messageConfirm,
        NotificationMails: this.notificationMails,
        Config: this.config,
      })
    },
    handleAddField () {
      const aux = JSON.parse(JSON.stringify(defaultField))
      aux.id = JSON.parse(JSON.stringify(utils.getNewID()))
      this.config.push(aux)
      this.handleChange()
    },
    handleDeleteItem (v) {
      this.config = this.config.filter(x => x.id !== v)
      this.handleChange()
    },
  },
}
</script>

